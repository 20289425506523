<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="top">
        <div class="top-left">
          <!--        <Button type="primary" @click="add" style="margin-right: 10px;" >{{$t('dataset_data_add')}}</Button>-->
<!--          <Button type="primary" @click="toFeedback" style="margin-right: 10px;" >{{$t('dataset_data_feedback')}}</Button>-->
          <Button type="primary" @click="toErrorProne()" style="margin-right: 10px;">易错题列表</Button>
          <Button type="primary" @click="toDataCorrection" style="margin-right: 10px;" >{{$t('dataset_data_correction')}}</Button>
          <Button type="primary" @click="toDataCollect" style="margin-right: 10px;" >{{$t('dataset_data_collect')}}</Button>
<!--          <Button type="primary" @click="toDataRecords" style="margin-right: 10px;">操作记录</Button>-->
          <Button type="primary" @click="toSimilar" style="margin-right: 10px;">相似题</Button>
          <Button type="primary" @click="toStarStatistics" style="margin-right: 10px;" >星级统计</Button>
          <Button type="primary" @click="toEditData" style="margin-right: 10px;">单题编辑</Button>
          <Button type="primary" @click="toSearchRecord" style="margin-right: 10px;" >搜索历史记录</Button>
          <Button type="primary" @click="selectCategoryDrawer=true">单题添加</Button>
          <!--        <Button type="primary" @click="importCategory">导入</Button>-->
        </div>
        <div class="top-right">
          <Input v-model="keyword"
                 :placeholder="$t('common_search_keyword')"
                 clearable
                 search
                 :enter-button="true"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
          />
        </div>
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="typeId == index ? 'active' : ''" v-for="(item,index) in typesObj" :key="index + 'type'" @click="changeSearch({id:index},'typeId')">{{item}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="secondCategoryList.length > 1">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="thirdCategoryList.length > 1">
          <div class="name">
            三级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'thirdCategory'" @click="changeSearch(item,'curThirdCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="typeList.length > 1">
          <div class="name">
            题型:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curTypeId == item.id ? 'active' : ''" v-for="item in typeList" :key="item.id + 'type'" @click="changeSearch(item,'curTypeId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="statusList.length > 1">
          <div class="name">
            审核状态:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curStatusId == item.id ? 'active' : ''" v-for="item in statusList" :key="item.id + 'status'" @click="changeSearch(item,'curStatusId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
           答对率范围:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curRightRateId == item.id ? 'active' : ''" v-for="item in rightRateList" :key="item.id + 'rightRate'" @click="changeSearch(item,'curRightRateId')">{{item.name}}</div>
              <Input v-model="rightRateSmall" @on-focus="rightRateOnFoucs" type="number" placeholder="正确率大于值" style="width: 120px;padding: 0;margin:0 10px" />
              <Input v-model="rightRateBig" @on-focus="rightRateOnFoucs" type="number" placeholder="正确率小于等于值" style="width: 140px;padding: 0;margin-right: 10px" />
              <Button type="primary" @click="confirmRightRate">确定</Button>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            星级范围:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curStarTypeId == item.id ? 'active' : ''" v-for="item in starTypeList" :key="item.id + 'star'" @click="changeSearch(item,'curStarTypeId')">{{item.name}}</div>
              <Input v-model="starTypeSmall" @on-focus="starTypeOnFoucs" type="number" placeholder="星级范围大于值" style="width: 120px;padding: 0;margin:0 10px" />
              <Input v-model="starTypeBig" @on-focus="starTypeOnFoucs" type="number" placeholder="星级范围小于等于值" style="width: 140px;padding: 0;margin-right: 10px" />
              <Button type="primary" @click="confirmStarType">确定</Button>
            </div>
          </div>
        </div>
      </div>
      <div class="select-num">
        <p>已选择<span>{{selectDataLen}}</span>条</p>
        <Button type="primary" @click="batchVerify">批量过审</Button>
        <Button type="primary" @click="batchOff" style="margin-left: 20px;">批量操作疑难题</Button>
        <Button type="primary" @click="batchLower" style="margin-left: 20px;">批量下架</Button>
<!--        <Button type="primary" @click="batchDele" style="margin-left: 20px;">批量删除</Button>-->
      </div>

      <Table border :columns="columns" :loading="loading" :data="data" @on-selection-change="selectModalData" @on-sort-change="sortChange"></Table>
      <div class="page">
        <Page :total="total" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
      <!-- <Modal
          v-model="batchOffModal"
          title="批量操作"
      >
        <Form ref="batchOffForm" :model="batchOffForm" :rules="ruleBatchOff" :label-width="80">
          <FormItem prop="message" label="原因:">
            <Input type="textarea" v-model="batchOffForm.message" placeholder="请输入原因">
            </Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="batchOffCancel">取消</Button>
          <Button type="primary" @click="batchOffConfirm">确定</Button>
        </div>
      </Modal> -->
      <el-drawer :visible.sync="batchOffModal" :size="800">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">批量操作</div>
          <div class="drawer-title-btns">
            <Button @click="batchOffCancel" style="margin-right: 10px;">取消</Button>
            <Button type="primary" @click="batchOffConfirm">确定</Button>
          </div>
        </div>
        <div style="padding: 20px;">
          <Form ref="batchOffForm" :model="batchOffForm" :rules="ruleBatchOff" :label-width="80">
            <FormItem prop="message" label="原因:">
              <Input type="textarea" v-model="batchOffForm.message" placeholder="请输入原因">
              </Input>
            </FormItem>
          </Form>
        </div>
      </el-drawer>

      <!-- <Modal
          v-model="correctionModal"
          title="编辑"
          width="70%"
      > -->
<!--        <div class="correction">-->
<!--          <div class="type">-->
<!--            <strong>{{ $t('trainingcamp_train_correction_type') }}:</strong>-->
<!--            <RadioGroup v-model="questionType" size="large" style="margin-left: 20px" @on-change="changeQuestionType">-->
<!--              <Radio v-for="item in typesList" :label="item.name" :key="item.value"></Radio>-->
<!--            </RadioGroup>-->
<!--          </div>-->
<!--          <div class="tit">-->
<!--            <div class="left">-->
<!--              <p class="data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--              <Richtxt v-if="correctionModal" :statusbar="true" :height="150" :dataObj="{val:correctionInfo.name}" @saveData="(data)=>saveCurData(data,correctionInfo,'name')" key="feedback"></Richtxt>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="option-item" v-for="(item,index) in correctionInfo.options" :key="item.id">-->
<!--            <div class="left">-->
<!--              <p class="data-tit">{{optionNameList[index]}}</p>-->
<!--              <p class="correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
<!--              <p class="dele" v-if="correctionInfo.options.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--              <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
<!--            </div>-->
<!--          </div>-->
<!--          <Button type="primary" @click="addOption">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
<!--          <div class="analysis">-->
<!--            <div class="left">-->
<!--              <p class="data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--              <Richtxt v-if="correctionModal" :statusbar="true" @saveData="(data)=>saveCurData(data,correctionInfo,'explain')" :height="150" :dataObj="{val:correctionInfo.explain}" ></Richtxt>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- <div v-if="correctionInfo.id && correctionModal">
          <EditData :allData="{data:correctionInfo,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>
        </div>

        <div slot="footer">
          <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
          <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
        </div>
      </Modal> -->
      <el-drawer :visible.sync="correctionModal" :size="1200">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">编辑</div>
          <div class="drawer-title-btns">
            <Button @click="correctionModal=false" style="margin-right: 10px;">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
            <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
          </div>
        </div>
        <div style="padding: 20px;">
          <div v-if="correctionInfo.id && correctionModal">
            <EditData :allData="{data:correctionInfo,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>
          </div>
        </div>
      </el-drawer>

      <!-- <Modal
          v-model="opListModal"
          title="操作记录"
          width="1000px"
      >
        <Table border :columns="opListColumns" :loading="opListLoading" :data="opListData" ></Table>
        <div style="margin-top: 20px;text-align: right">
          {{opListPage}}
          <Page :total="opListTotal" @on-change="changeOpListPage" @on-page-size-change="changeOpListPageSize" :current="opListPage" show-total show-sizer show-elevator></Page>
        </div>
      </Modal> -->
      <el-drawer :visible.sync="opListModal" :size="1200">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">操作记录</div>
          <div class="drawer-title-btns">
          </div>
        </div>
        <div style="padding: 20px;">
          <Table border :columns="opListColumns" :loading="opListLoading" :data="opListData" ></Table>
          <div style="margin-top: 20px;text-align: right">
            {{opListPage}}
            <Page :total="opListTotal" @on-change="changeOpListPage" @on-page-size-change="changeOpListPageSize" :current="opListPage" show-total show-sizer show-elevator></Page>
          </div>
        </div>
      </el-drawer>

      <!-- <Modal
          v-model="selectDataModal"
          :title="$t('project_select_type')"
          :footer-hide="true"
          :scrollable="true"
          class="select-data-modal"
          width="1000px"
      >
        <div class="tab-list">
          <div class="select-data-cont">
            <CategoryData @create="createGroup"></CategoryData>
          </div>
        </div>
      </Modal> -->
      <el-drawer :visible.sync="selectDataModal" :size="800">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ $t('project_select_type') }}</div>
          <div class="drawer-title-btns">
          </div>
        </div>
        <div style="padding: 20px;">
          <div class="tab-list">
            <div class="select-data-cont">
              <CategoryData @create="createGroup"></CategoryData>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>

    <!-- 单题添加 -->
    <el-drawer :visible.sync="addDataModal" :size="1200">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">单题添加</div>
        <div class="drawer-title-btns">
          <Button @click="addDataModal=false" style="margin-right: 10px;">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
          <Button @click="addDataConfirm()" type="primary">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <AddData v-if="addDataModal" :addDataModal="addDataModal" @changeData="changeQuestionData"></AddData>
      </div>
    </el-drawer>

    <!-- 选择分类抽屉 -->
    <AddKnowledgeGraph :show="selectCategoryDrawer" :drawerType="'select'" @close="selectCategoryDrawerClose"/>

  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
// import Richtxt from '@/components/production/richtxt.vue';
import CategoryData from "@/views/trainingcamp/components/common/categoryData";
import EditData from './components/data/editData'
import AddData from '@/views/course/components/addData';
import AddKnowledgeGraph from "./components/addKnowledgeGraph/addKnowledgeGraph.vue"

export default {
  name: "dataView",
  data(){
    return{
      addDataModal:false,
      newData:{},
      selectCategoryDrawer:false,
      selectCatetoryId:"",
      typeId:"0",
      typesObj:{},
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('dataset_data_table_index'),
          width: 80,
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.id + (params.row.similar_parent_id > 0 ? '('+params.row.similar_parent_id+')' : ''))
          }

        },
        {
          title: this.$t('dataset_data_table_title'),
          key: 'name',
          minWidth: 200,
          render: (h, params) => {
            return h('p', {
              style:{
                color:'#2db7f5',
                cursor:'pointer'
              },
              on:{
                click:()=>{
                  const routeUrl = this.$router.resolve({
                    name: 'data-detail',
                    params: {
                      id: params.row.id,
                      tab:'cont'
                    }
                  })
                  window.open(routeUrl.href, "_blank");
                }
              }
            }, params.row.title);
          }
        },
        {
          title: this.$t('dataset_data_table_type')+'/'+this.$t('dataset_data_table_level'),
          key: 'type',
          minWidth: 120,
          render:(h,params)=>{
            return h('span',this.typeObj[params.row.type] + '/'+this.levelObj[params.row.level])
          }
        },
        // {
        //   title: this.$t('dataset_data_table_level'),
        //   key: 'level',
        //   width:90,
        //   render:(h,params)=>{
        //     return h('span',this.levelObj[params.row.level])
        //   }
        // },
        {
          title: this.$t('dataset_data_table_display'),
          minWidth: 120,
          render:(h,params)=>{
            let message = params.row.last_record.message ? '('+ params.row.last_record.message +')' : '';
            // return h('span',this.statusObj[params.row.status]+message)
            return util.tableColumnTooltip(h,this.statusObj[params.row.status]+message);
          }
        },
        // {
        //   title: '分类',
        //   width:120,
        //   render:(h,params)=>{
        //     let categoryName = '';
        //     params.row.categoryList.forEach((item,index)=>{
        //       if(index < params.row.categoryList.length - 1){
        //         categoryName += item.name + '-';
        //       }else{
        //         categoryName += item.name;
        //       }

        //     });
        //     return h('span',categoryName)
        //   }
        // },
        // {
        //   title: this.$t('dataset_data_table_options_number'),
        //   key: 'options_number',
        // },
        {
          title: this.$t('dataset_data_table_anum'),
          key: 'answer_count',
          minWidth: 80,
        },
        {
          title: this.$t('dataset_data_table_rnum'),
          key: 'right_count',
          minWidth: 80,
        },
        {
          title: '正确率',
          key: 'right_rate',
          sortable:'custom',
          minWidth: 100,
        },
        {
          title: '星级评分',
          key: 'star',
          sortable:'custom',
          minWidth: 110,
        },
        {
          title: this.$t('dataset_data_table_created_at'),
          key: 'created_at',
          sortable:'custom',
          minWidth: 150,
          render: (h, para) => {
            return util.tableColumnTooltip(h,util.timeFormatter(
                new Date(+para.row.created_at * 1000),
                'yyyy-MM-dd hh:mm'
            ));
          }
        },
        {
          title: this.$t('dataset_data_table_updated_at'),
          key: 'updated_at',
          sortable:'custom',
          minWidth: 150,
          render: (h, para) => {
            // return h('span', para.row.updated_at > 0 ? util.timeFormatter(
            //         new Date(+para.row.updated_at * 1000),
            //         'yyyy-MM-dd hh:mm'
            //     ) : ''
            // );
            return util.tableColumnTooltip(h,para.row.updated_at > 0 ? util.timeFormatter(
                    new Date(+para.row.updated_at * 1000),
                    'yyyy-MM-dd hh:mm'
                ) : '');
          }
        },
        {
          title: this.$t('dataset_data_table_op'),
          width:280,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.opListModal = true;
                    this.opListPage = 1;
                    this.opListPageSize = 10;
                    this.curData = params.row;
                    this.getRecordList();
                  }
                }
              }, '操作记录'),
              h("el-button",{
                props:{
                  size:"mini",
                  type:"warning"
                },
                style:{
                  marginRight:"10px",
                  marginTop:"5px"
                },
                on:{
                  click:()=>{
                    this.correctionModal = true;
                    this.curData = params.row;
                    this.correction();
                  }
                }
              },this.$t('system_edit')),
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.check(params.row)
              //     }
              //   }
              // }, this.$t('system_check')),
              // h('el-button', {
              //   props: {
              //     type: 'primary',
              //     size: 'mini'
              //   },
              //   style: {
              //     marginRight: '10px',
              //     marginTop: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       // this.correctionModal = true;
              //       this.curData = params.row;
              //       this.batchOffModal = true;
              //       this.batchOffForm.message = '';
              //       this.isBatchLower = false;
              //       this.batchType='lower';
              //     }
              //   }
              // }, '下架'),
              // h('Button', {
              //   props: {
              //     type: 'error',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginTop: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.deleteRelation(params.row);
              //     }
              //   }
              // }, '删除关联'),

              h("el-select",{
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:""
                },
                style:{
                  width:"75px"
                },
                on:{
                  change:(event)=>{
                    if(event==="下架"){
                      this.curData = params.row;
                      this.batchOffModal = true;
                      this.batchOffForm.message = '';
                      this.isBatchLower = false;
                      this.batchType='lower';
                    }else if(event==="审核"){
                      // this.correctionModal = true;
                      this.curData = params.row;
                      this.dataVerify();
                    }else if(event==="疑难"){
                      // this.correctionModal = true;
                      this.curData = params.row;
                      this.batchOffModal = true;
                      this.batchOffForm.message = '';
                      this.isBatchOff = false;
                      this.batchType='off';
                    }else if(event===this.$t('system_dele')){
                      this.delete(params.row);
                    }
                  }
                }
              },[
                h("el-option",{
                  props:{
                    value:"下架"
                  }
                },"下架"),
                h("el-option",{
                  props:{
                    value:"审核"
                  }
                },"审核"),
                h("el-option",{
                  props:{
                    value:"疑难"
                  }
                },"疑难"),
                h("el-option",{
                  props:{
                    value:this.$t('system_dele')
                  },
                  style:{
                    color:"red"
                  }
                },this.$t('system_dele')),
              ])

            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      keyword:'',
      total:0,
      type:'',
      modalTit:'',
      parentCategoryArr:[],
      typeObj:{},
      levelObj:{},
      statusObj:{},
      parentCategoryList:[],
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      curThirdCategoryId:'-1',//当前三级分类
      thirdCategoryList:[],
      cacheSelectData:{},
      selectModalDataList:[],
      typeList:[],
      statusList:[],
      // 题型id
      curTypeId:'-1',
      curStatusId:'-1',
      selectDataLen:0,
      batchOffModal:false,
      batchOffForm:{
        message:'',
      },
      ruleBatchOff:{
        message: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ],
      },
      sort:'',
      orderby:'',
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      questionType:'',
      typesList:[],
      curData:{},
      rightRateSmall:'',
      rightRateBig:'',
      searchRightRateSmall:'',
      searchRightRateBig:'',
      curRightRateId:'',
      rightRateList:[],
      curStarTypeId:'',
      starTypeList:[],
      starTypeSmall:'',
      starTypeBig:'',
      searchStarTypeSmall:'',
      searchStarTypeBig:'',
      isBatchOff:false,
      isBatchLower:false, //是否为批量处理下架
      opListModal:false,
      opListColumns:[
        {
          title:'题目ID',
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.data.id)
          }

        },
        {
          title:'标题',
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.data.title)
          }

        },
        {
          title:'类型',
          key: 'type',
          render: (h, para) => {
            return h('span', this.typeObj[para.row.data.type]);
          }

        },
        {
          title:'备注',
          key:'message',

        },
        {
          title:'创建人',
          render: (h, para) => {
            return h('span', para.row.user && para.row.user.nickname);
          }

        },
        {
          title:'创建时间',
          key:'id',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }

        },
      ],
      opListLoading:false,
      opListData:[],
      opListPage:1,
      opListTotal:0,
      opListPageSize:10,
      selectDataModal:false,
      deleType:'',
      sourceFroms:'',
      allCategoryList:[],
    }
  },
  components:{
    DeleModal,
    // Richtxt,
    CategoryData,
    EditData,
    AddData,
    AddKnowledgeGraph
  },
  mounted(){
    this.getCategoryList();
    this.getList();
  },
  methods:{
    getRecordList(){
      this.opListLoading = true;
      let params = {
        page:this.opListPage,
        pageSize:this.opListPageSize,
        data_id:this.curData.id
      };
      this.api.dataset.dataRecordList(params).then((res)=>{
        this.opListLoading = false;

        this.opListData = res.list;
        this.opListTotal = Number(res.count);
        // this.typeObj = res.types;
        // console.warn("类型",res.types);
        // this.levelObj = res.levels;
      }).catch((err)=>{
        this.opListLoading = false;
      })
    },
    changeOpListPage(page){
      this.opListPage = page;
      this.getRecordList();
    },
    changeOpListPageSize(pageSize){
      this.opListPageSize = pageSize;
      this.opListPage = 1;
      this.getRecordList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    rightRateOnFoucs(){
      this.curRightRateId = -1;
    },
    starTypeOnFoucs(){
      this.curStarTypeId = -1;
    },
    confirmRightRate(){
      this.page = 1;
      this.searchRightRateSmall = this.rightRateSmall;
      this.searchRightRateBig = this.rightRateBig;
      this.getList();
    },
    confirmStarType(){
      this.page = 1;
      this.searchStarTypeSmall = this.starTypeSmall;
      this.searchStarTypeBig = this.starTypeBig;
      this.getList();
    },
    sortChange(e){
      console.log(e,'orderorderorder')
      if(e.order == 'asc' || e.order == 'desc'){
        this.sort = e.order;
        this.orderby = e.key;
      }else{
        this.sort = '';
        this.orderby = '';
      }
      this.getList();
    },
    getList(){
      this.loading = true;
      console.log(this.searchRightRateSmall,this.searchRightRateBig,'this.searchRightRateSmall')
      let params = {
        site_id:-1,
        user_id:-1,
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        sort:this.sort,
        right_rate_start:this.searchRightRateSmall,
        right_rate_end:this.searchRightRateBig,
        star_start:this.searchStarTypeSmall,
        star_end:this.searchStarTypeBig,
        right_rate_type:this.curRightRateId,
        type:this.curTypeId,
        category_type:this.typeId
      };
      if(this.curStarTypeId && this.curStarTypeId != -1){
        params.star_type = this.curStarTypeId;
      }
      if(this.curRightRateId && this.curRightRateId != -1){
        params.right_rate_type = this.curRightRateId;
      }
      if(this.sort){
        params.orderby = this.orderby;
      }
      if(this.curThirdCategoryId && this.curThirdCategoryId != -1){
        params.category_id = this.curThirdCategoryId;
      }else if(this.cursecondCategoryId && this.cursecondCategoryId != -1){
        params.category_id = this.cursecondCategoryId;
      }else if(this.curCategoryId && this.curCategoryId != -1){
        params.category_id = this.curCategoryId;
      }
      if(this.curTypeId && this.curTypeId != -1){
        params.type = this.curTypeId;
      }
      if(this.curStatusId && this.curStatusId != -1){
        params.status = this.curStatusId;
      }
      this.api.dataset.dataList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        // this.parentCategoryArr = parentCategoryArr;
        this.typeObj = res.types;
        this.levelObj = res.levels;
        this.statusObj = res.statuses;
        this.parentCategoryList = res.parentCategoryList;
        this.typeList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.types){
          this.typeList.push({
            name:res.types[name],
            id:name
          })
        }
        this.statusList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.statuses){
          this.statusList.push({
            name:res.statuses[name],
            id:name
          })
        }

        this.rightRateList = [{
          name:'全部',
          id:''
        }];
        for(let name in res.right_rate_types){
          this.rightRateList.push({
            name:res.right_rate_types[name],
            id:name
          })
        }

        this.starTypeList = [{
          name:'全部',
          id:''
        }];
        for(let name in res.star_types){
          this.starTypeList.push({
            name:res.star_types[name],
            id:name
          })
        }

        this.data.forEach((item)=>{
          let len = this.cacheSelectData[this.page] && this.cacheSelectData[this.page].filter((sItem)=>{
            return sItem.id == item.id;
          });
          if(len && len.length){
            item._checked = true;
          }
        })
        if(this.cacheSelectData[this.page]){
          this.selectModalDataList = this.cacheSelectData[this.page];
        }else{
          this.selectModalDataList = [];
        }
      }).catch((err)=>{
        this.loading = false;
      })
    },
    batchDele(){
      this.deleModalStatus = true;
      this.deleType='batch';
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
      this.deleType='';
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = { }
      if(this.deleType){
        this.cacheSelectData[this.page] = JSON.parse(JSON.stringify(this.selectModalDataList));
        let arr = [];
        let ids = '';
        for(let name in this.cacheSelectData){
          arr = arr.concat(this.cacheSelectData[name]);
        }
        if(!arr.length){
          this.$Message.warning(this.$t('user_mygroups_select_data_message'));
          return;
        }else{
          ids = arr.map((item)=>{
            return item.id;
          }).join(',');
        }
        data.id = ids;
      }else{
        data.id = this.curDeleData.id;
      }
      this.modalLoading = true;
      this.api.dataset.dataDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.cacheSelectData[this.page] = JSON.parse(JSON.stringify(this.selectModalDataList));
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.cacheSelectData = {};
      this.selectModalDataList = [];
      this.getList();
    },
    selectModalData(data){
      this.selectModalDataList = data;
      let arr = [];
      for(let name in this.cacheSelectData){
        if(name != this.page){
          arr = arr.concat(this.cacheSelectData[name]);
        }
      }
      this.selectDataLen = arr.length + this.selectModalDataList.length;
    },
    batchVerify(){  //批量过审
      console.log(this.selectModalDataList,this.userPage)
      this.cacheSelectData[this.page] = JSON.parse(JSON.stringify(this.selectModalDataList));
      let arr = [];
      let ids = '';
      for(let name in this.cacheSelectData){
        arr = arr.concat(this.cacheSelectData[name]);
      }
      if(!arr.length){
        this.$Message.warning(this.$t('user_mygroups_select_data_message'));
        return;
      }else{
        ids = arr.map((item)=>{
          return item.id;
        }).join(',');
      }
      console.log(arr)
      let params = {
        id:ids,
        status:1
      };
      this.api.dataset.datasetDataVerify(params).then((res)=>{
        this.$Message.success('审核成功');
        this.cacheSelectData = {};
        this.selectModalDataList = [];
        this.getList();
      })
    },
    dataVerify(){
      let params = {
        id:this.curData.id,
        status:1
      };
      this.api.dataset.datasetDataVerify(params).then((res)=>{
        this.$Message.success('审核成功');
        this.cacheSelectData = {};
        this.selectModalDataList = [];
        this.getList();
      })
    },
    batchLower(){
      this.batchOffModal = true;
      this.batchOffForm.message = '';
      this.isBatchLower = true;
      this.batchType='lower';
    },
    batchOff(){
      this.batchOffModal = true;
      this.batchOffForm.message = '';
      this.isBatchOff = true;
      this.batchType='off';
    },
    batchOffConfirm(){
      this.$refs['batchOffForm'].validate((valid) => {
        if (valid) {
          let ids = '';
          console.log(this.isBatchOff,'this.isBatchOff')
          if(this.isBatchOff || this.isBatchLower){
            this.cacheSelectData[this.page] = JSON.parse(JSON.stringify(this.selectModalDataList));
            let arr = [];
            for(let name in this.cacheSelectData){
              arr = arr.concat(this.cacheSelectData[name]);
            }
            if(!arr.length){
              this.$Message.warning(this.$t('user_mygroups_select_data_message'));
              return;
            }else{
              ids = arr.map((item)=>{
                return item.id;
              }).join(',');
            }
          }else{
            ids = this.curData.id;
          }
          let status;
          if(this.batchType == 'lower'){
            status = 3;
          }else if(this.batchType == 'off'){
            status = 2;
          }
          let params = {
            id:ids,
            status:status,
            message:this.batchOffForm.message
          };
          this.api.dataset.datasetDataVerify(params).then((res)=>{
            this.$Message.success('操作成功');
            this.cacheSelectData = {};
            this.selectModalDataList = [];
            this.batchOffModal = false;
            this.getList();
          })
        }
      })

    },
    batchOffCancel(){
      this.batchOffModal = false;
    },
    toFeedback(){
      this.$router.push({
        path:'/dataset/feedback',
        query:{

        }
      })
    },
    toDataCorrection(){
      this.$router.push({
        path:'/dataset/datacorrection',
        query:{

        }
      })
    },
    // 去易错题列表
    toErrorProne(){
      this.$router.push({
        path:"/dataset/errorpronelist"
      });
    },
    toDataCollect(){
      this.$router.push({
        path:'/dataset/datacollect',
        query:{

        }
      })
    },
    toDataRecords(dataid){
      this.$router.push({
        path:'/dataset/datarecords/'+(dataid > 0 ? dataid :''),
        query:{

        }
      })
    },
    toSimilar(){
      this.$router.push({
        path:'/dataset/similar',
      })
    },
    changeSearch(data,name){
      if(name == 'curCategoryId'){
        this.cursecondCategoryId = '-1';
        this.curThirdCategoryId = '-1';
      }else if(name == 'cursecondCategoryId'){
        this.curThirdCategoryId = '-1';
      }else if(name == 'curStarTypeId'){
        this.starTypeSmall = '';
        this.starTypeBig = '';
        this.searchStarTypeSmall = '';
        this.searchStarTypeBig = ''
      }else if(name == 'curRightRateId'){
        this.rightRateSmall = '';
        this.rightRateBig = '';
        this.searchRightRateSmall = '';
        this.searchRightRateBig = '';
      }else if(name == 'typeId'){
        this.curCategoryId="-1";
        this.cursecondCategoryId = "-1";
        this.curThirdCategoryId = "-1";
      }
      this[name] = data.id;
      this.page = 1;
      this.getCategoryList();
      this.getList();
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        is_hide_children:1,
        site_id:-1,
        user_id:-1,
        type:this.typeId
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categorySecondList
        ];
        console.log(this.secondCategoryList,'this.secondCategoryList')
        this.thirdCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
            ...res.categoryThirdList
        ]
        this.typesObj=res.types;
      })
    },
    getDetail(){
      let params = {
        id:this.curData.id
      };
      // this.loading = true;
      this.correctionInfo = {};
      this.allCategoryList = [];
      this.api.dataset.dataDetail(params).then((res)=>{
        // this.loading = false;
        this.correctionInfo = JSON.parse(JSON.stringify(res.info));
        this.correctionInfo.labelKeywords = this.correctionInfo.labelKeywords.map((item)=>{
          return item.label_id
        });
        this.correctionInfo.labelKeypoints = this.correctionInfo.labelKeypoints.map((item)=>{
          return item.label_id
        });


        this.questionType = res.types[this.correctionInfo.type];
        this.typesList = [];
        for(let name in res.types){
          this.typesList.push({
            name:res.types[name],
            value:name
          })
        }
        this.sourceFroms = res.source_froms;

        this.$set(this.correctionInfo,'categoryIds',[]);
        for(let name in this.correctionInfo.categoryList){
          this.correctionInfo.categoryIds.push(name)
        }

        this.allCategoryList = [];
        for(let name in res.info.categoryList){
          let names = res.info.categoryList[name].map(sItem=>{
            return sItem.name+'(id:'+sItem.id+')'
          }).join('-');
          this.allCategoryList.push({
            name:names,
            value:name
          })
        }

      }).catch((err)=>{
        // this.loading = false;
      })
    },
    changeData(data){
      console.log(data,"-=-=-==");
      this.correctionInfo = JSON.parse(JSON.stringify(data));
    },
    correction(){
      this.correctionModal = true;
      this.getDetail();
    },
    saveCurData(curData,data,name){
      data[name] = curData;
    },
    changeQuestionType(){
      this.correctionInfo.type = this.typesList.filter((item)=>{
        return item.name == this.questionType
      })[0].value;
      this.correctionInfo.options.forEach((item)=>{
        item.is_correct = '0';
      });
    },
    // changeCorrect(data){  //修改正确选项
    //   console.log(this.correctionInfo.type,'this.correctionInfo.typethis.correctionInfo.type')
    //   if(this.correctionInfo.type == '1' || this.correctionInfo.type == '3'){ //单选
    //     if(data.is_correct == '0'){
    //       this.correctionInfo.options.forEach((item)=>{
    //         item.is_correct = '0';
    //       });
    //       this.$nextTick(()=>{
    //         data.is_correct = '1';
    //       })
    //
    //       console.log(data)
    //     }else{
    //       this.$nextTick(()=>{
    //         data.is_correct = '0';
    //       })
    //     }
    //   }else{
    //     data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
    //   }
    //
    // },
    // addOption(){  //添加选项
    //   if(this.correctionInfo.options.length == 6){
    //     this.$Message.warning(this.$t('trainingcamp_train_option_max'));
    //     return;
    //   }
    //   this.correctionInfo.options.push({
    //     name:'',
    //     id:'0',
    //     is_correct:'0'
    //   })
    // },
    // deleOption(index){  //删除选项
    //   this.correctionInfo.options.splice(index, 1);
    // },
    confirmCorrection(){  //确认纠错
      if(!this.correctionInfo.categoryIds.length){
        this.$Message.warning('请选择分类');
        return;
      }
      if(!this.correctionInfo.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }

      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3' || this.correctionInfo.type == '4') {
        if (!this.correctionInfo.options.length) { //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        for(let i=0;i<this.correctionInfo.options.length;i++){
          if(!this.correctionInfo.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
            break;
          }
        }
      }else{
        this.correctionInfo.options = [];
      }
      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3') {
        let len = this.correctionInfo.options.filter((item)=>{
          return item.is_correct == '1';
        }).length

        if(!len){ //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }

        if(this.correctionInfo.type == '1' || this.correctionInfo.type == '3'){
          if(len > 1){
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }

      if(!this.correctionInfo.labelKeywords.length){
        this.$Message.warning('请选择标签');
        return;
      }


      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }
      // let type = this.typesList.filter((item)=>{
      //   return item.name == this.questionType
      // })[0].value;

      let params = {
        id:this.correctionInfo.id,
        name:this.correctionInfo.name,
        explain:this.correctionInfo.explain,
        options:JSON.stringify(this.correctionInfo.options),
        type:this.correctionInfo.type,
        level:this.correctionInfo.level,
        status:this.correctionInfo.status,
        source:this.correctionInfo.source,
        label_keyword_ids:this.correctionInfo.labelKeywords.join(','),
        label_keypoint_ids:this.correctionInfo.labelKeypoints.join(','),
        category_ids:this.correctionInfo.categoryIds.join('|'),
        site_id:'-1',
        title:this.correctionInfo.title,
      };

      this.api.dataset.dataUpdate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_cont_edit_success'));
        // this.getInfo()
        this.getList();
        this.$emit('edit')
      })
    },
    importCategory(){
      this.selectDataModal = true;
    },
    createGroup(data){
      let params = {
        category_id:data
      };
      this.api.dataset.groupCreate(params).then((res)=>{
        this.selectDataModal = false;

        this.$router.push({
          path:'/dataset/import-category-data',
          query:{
            groupId:res.id,
          }
        })
      })
    },
    toStarStatistics(){ //星级统计
      this.$router.push({
        path:'/dataset/star-statistics'
      })
    },
    toEditData(){  //修改标签
      this.$router.push({
        path:'/dataset/edit-data'
      })
    },
    toSearchRecord(){
      this.$router.push({
        path:'/dataset/data-search-record'
      })
    },
    // deleteRelation(data){
    //   this.curDeleData = data;
    //   this.deleModalStatus = true;
    //   this.deleteRelationStatus = true;
    // }

    selectCategoryDrawerClose(id){
      if(!id){
        this.selectCategoryDrawer=false;
        return;
      }
      this.selectCatetoryId=id;
      this.selectCategoryDrawer=false;
      this.addDataModal=true;
    },

    // 单题添加抽屉相关
    changeQuestionData(data){
      this.newData = JSON.parse(JSON.stringify(data));
    },
    addDataConfirm(){
      if(!this.newData.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(this.newData.type == '1' || this.newData.type == '2' || this.newData.type == '3'){
        if(!this.newData.options.length){ //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        let len = this.newData.options.filter((item)=>{
          return item.is_correct == '1';
        }).length

        if (!len && this.newData.type != '4') { //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }
        for(let i=0;i<this.newData.options.length;i++){
          if(!this.newData.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
            break;
          }
        }
        if(this.newData.type == '1' || this.newData.type == '3'){
          if(len > 1){
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }
      if(!this.newData.labelKeywords.length){
        this.$Message.warning('请选择标签');
        return;
      }
      if(this.newData.type == 4){
        this.newData.options.forEach((item)=>{
          item.is_correct = '1';
        })
      }
      let params = {
        name:this.newData.name,
        explain:this.newData.explain,
        options:JSON.stringify(this.newData.options),
        type:this.newData.type,
        level:this.newData.level,
        status:this.newData.status,
        source:this.newData.source,
        label_keyword_ids:this.newData.labelKeywords.join(','),
        label_keypoint_ids:this.newData.labelKeypoints.join(','),
        category_id:this.selectCatetoryId,
      };
      this.api.dataset.dataCreate(params).then(()=>{
        this.addDataModal = false;
        this.$Message.success('添加成功');
        this.getList()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-right{
      width: 200px;
      text-align: right;
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .select-num{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >p{
      margin-right: 20px;
      >span{
        padding: 0 5px;
        color:#2d8cf0;
      }
    }
  }
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;
    }
  }
  .analysis{
    margin-top: 20px;
  }
}


</style>
